
import { Observable, of as observableOf, throwError as observableThrowError, timer } from 'rxjs';

import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FileUploader } from 'ng2-file-upload';
import { isArray } from 'util';
import { environment } from 'src/environments/environment';
// from https://www.learnrxjs.io/operators/error_handling/retrywhen.html#example-2-customizable-retry-with-increased-duration
export const genericRetryStrategy = ({
                                       maxRetryAttempts = 3,
                                       scalingDuration = 1000,
                                       excludedStatusCodes = [],
                                     }: {
  maxRetryAttempts?: number,
  scalingDuration?: number,
  excludedStatusCodes?: number[]
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (
        retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.find(e => e === error.status)
      ) {
        return observableThrowError(error);
      }

      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration);
    }),
  );
};

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

  private readonly base_api = environment.api_url;

  constructor(private http: HttpClient) { }

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(`${this.base_api}/console/${url}`, this.getRequestOptions());
  }

  post<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(`${this.base_api}/console/${url}`, data, this.getRequestOptions());
  }

  put<T>(url: string, data: any): Observable<T> {
    return this.http.put<T>(`${this.base_api}/console/${url}`, data, this.getRequestOptions());
  }

  delete<T>(url: string, id: number | string): Observable<T> {
    return this.http.delete<T>(`${this.base_api}/console/${url}/${id}`, this.getRequestOptions());
  }

  setToken(token: string): void {
    localStorage.setItem('access_token', token);
  }

  getToken(): string {
    return localStorage.getItem('access_token');
  }

  private getRequestOptions() {
    const headers = {};
    if (localStorage.getItem('access_token')) {
      headers['Authorization'] = `Bearer ${this.getToken()}`;
    }
    // headers['Client-Load-At'] = this.clientLoadAt;
    // headers['Client-Version'] = environment.version;
    // headers['Client-Build-Date'] = environment.buildDate;
    // headers['Client-Lang'] = this.translateService.currentLang;
    // headers['Request-URL'] = location.href;

    return {
      headers,
    };
  }
  postDownloadBlob(url, data) {
    const apiUrl = `${this.base_api}/console/${url}`;
    return this.http.post(
      apiUrl,
      data,
      {responseType: 'blob', ...this.getRequestOptions()},
    ).pipe(
      catchError((error) => this.handleBlobError(error)),
    );
  }

  handleBlobError(error: HttpErrorResponse) {
    if (error.error instanceof Blob) {
      if (error.status === 422) {
        const reader = new FileReader();
        reader.readAsText(error.error);

        // this will make notification show after error is handled but it may ok for me
        reader.onload = () => {
          const json = reader.result as string;
          const obj = JSON.parse(json);
          console.log(obj)
        };
      }
    }

    return observableThrowError(error);
  }

}
