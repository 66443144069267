import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  isCollapsed = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }


  logout(e): void {
    localStorage.removeItem('access_token');
    this.router.navigate(['auth']);
  }

}
