<nz-layout class="app-layout overflow-hidden">
  <nz-sider class="menu-sidebar"
            nzCollapsible
            nzWidth="256px"
            nzBreakpoint="md"
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null">
    <div class="sidebar-logo">
      <a href="https://smemove.com" target="_blank">
        <h1>SMEMove console</h1>
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-submenu nzOpen nzTitle="Dashboard" nzIcon="dashboard">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/dashboard">Welcome</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="Discount Codes" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter >
            <a routerLink="/discount-codes">List discount codes</a>
          </li>
        </ul>
      </li>

      <li nz-submenu nzOpen nzTitle="Partners" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter >
            <a routerLink="/partners">List partners</a>
          </li>
        </ul>
      </li>

      <li nz-submenu nzOpen nzTitle="Billing" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter >
            <a routerLink="/receipt">Transfer Receipt</a>
          </li>
          <li nz-menu-item nzMatchRouter >
            <a routerLink="/withholding">Withholding</a>
          </li>
        </ul>
      </li>

      <li nz-submenu nzOpen nzTitle="Corporation" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter >
            <a routerLink="/corporations/edit">Edit Corporation</a>
          </li>
          <li nz-menu-item nzMatchRouter >
            <a routerLink="/corporations/replace-billing">Replace Billing</a>
          </li>
          <li nz-menu-item nzMatchRouter >
            <a routerLink="/corporations/add-discount-code">Add discount code</a>
          </li>
        </ul>
      </li>

      <li nz-submenu nzOpen nzTitle="Outbound" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter >
            <a routerLink="/outbound">Outbound</a>
          </li>
        </ul>
      </li>


      <li nz-submenu nzOpen nzTitle="Settings" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter >
            <a routerLink="/users">Users</a>
          </li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header flex justify-between">
        <div>
          <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i class="trigger"
               nz-icon
               [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
               ></i>
          </span>
        </div>
        <div>
          <button nz-button nzType="default"  (click)="logout($event)" >Logout</button>
        </div>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content overflow-y-auto">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>

