import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Paginate } from '../interfaces/paginate';
import { SmemovePartner } from '../interfaces/smemove-partner';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private apiService: ApiClientService) { }

  create(data: any): Observable<SmemovePartner> {
    return this.apiService.post('smemove-partners', data);
  }

  partners(query: string = '?'): Observable<Paginate<SmemovePartner>> {
    return this.apiService.get(`smemove-partners${query}`);
  }

  inactive(id: number): Observable<SmemovePartner> {
    return this.apiService.put(`smemove-partners/${id}/in-active`, {id});
  }

  active(id: number): Observable<SmemovePartner> {
    return this.apiService.put(`smemove-partners/${id}/active`, {id});
  }

  update(id: number, data: any): Observable<SmemovePartner> {
    return this.apiService.put(`smemove-partners/${id}/update`, data);
  }
}
