import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizedGuard } from './core/guards/authorized.guard';
import { HasPermissionGuard } from './core/guards/has-permission.guard';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then (m => m.AuthModule)
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthorizedGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'discount-codes',
        canActivate: [HasPermissionGuard],
        data: {
          permissions: ['DISCOUNT_CODE']
        },
        loadChildren: () => import ('./discount-code/discount-code.module').then(m => m.DiscountCodeModule)
      },
      {
        path: 'partners',
        canActivate: [HasPermissionGuard],
        data: {
          permissions: ['PARTNER']
        },
        loadChildren: () => import ('./partner/partner.module').then(m => m.PartnerModule)
      },
      {
        path: 'corporations',
        canActivate: [HasPermissionGuard],
        data: {
          permissions: ['EDIT_CORPORATION', 'REPLACE_BILLING', 'ADD_DISCOUNT_CODE']
        },
        loadChildren: () => import ('./corporation/corporation.module').then(m => m.CorporationModule)
      },
      {
        path: 'outbound',
        canActivate: [HasPermissionGuard],
        data: {
          permissions: ['OUT_BOUND']
        },
        loadChildren: () => import ('./outbound/outbound.module').then(m => m.OutboundModule)
      },
      {
        path: 'receipt',
        canActivate: [HasPermissionGuard],
        data: {
          permissions: ['TRANSFER_RECEIPT']
        },
        loadChildren: () => import ('./receipt/receipt.module').then(m => m.ReceiptModule)
      },
      {
        path: 'withholding',
        canActivate: [HasPermissionGuard],
        data: {
          permissions: ['WHT']
        },
        loadChildren: () => import ('./withholding/withholding.module').then(m => m.WithholdingModule)
      },
      {
        path: 'users',
        canActivate: [HasPermissionGuard],
        data: {
          permissions: ['ADMIN']
        },
        loadChildren: () => import ('./users/users.module').then(m => m.UsersModule)
      },
      {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }

    ]
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
