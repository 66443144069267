import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginData } from '../interfaces/login-data';
import { User } from '../interfaces/user';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseApi = environment.base_url
  private currentLoginData = new BehaviorSubject<LoginData>(null);
  private currentUser = new BehaviorSubject<User>(null);

  private currentLoginRequest: Observable<LoginData> = null;

  constructor(private http: HttpClient, private apiService: ApiClientService, private router: Router) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post<any>(this.baseApi+ '/oauth/token', {
      grant_type: 'password',
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      username,
      password,
      scope: '*',
    }).pipe(mergeMap((data) => {
      if (data.access_token) {
        this.apiService.setToken(data.access_token);
        return this.refreshLoginData();
      }
      return null;
    }));
  }

  refreshLoginData() {
    this.currentLoginRequest = this.apiService.get('me').pipe(
      map(
        (data: LoginData) => {
          // this.apiService.isCORsPass = true;
          this.setLoginData(data);
          this.currentLoginRequest = null;

          console.log(this.currentUser.getValue())
          return data;
        },
        error => {
          localStorage.clear();
          this.setLoginData(null);
          return observableThrowError(error);
        }),
    );

    return this.currentLoginRequest;
  }
  private setLoginData(data: LoginData) {
    if (data) {
      this.currentLoginData.next(data);
      this.currentUser.next(data);
    } else {
      this.currentLoginData.next(null);
      this.currentUser.next(null);
    }

  }

  public getLoginData() {
    if (this.currentLoginData.getValue() === null) {
      if (this.currentLoginRequest !== null) {
        return this.currentLoginRequest;
      }
      return this.refreshLoginData();
    }
    return this.currentLoginData.asObservable();
  }


  hasPermissions(requirePerms: string | string[], byPassByAdminPermission = true) {
    const permissions = this.currentLoginData.getValue().console.permissions.split(',')
    if (this.isAdmin(permissions) && byPassByAdminPermission) {
      return true;
    }

    if (typeof (requirePerms) === 'string') {
      requirePerms = [requirePerms as string];
    }

    for (const perm of requirePerms) {
      if (!this.hasPermission(permissions, perm)) {
        return false;
      }
    }
    return true;
  }

  private hasPermission(p: string[], perm: string): boolean {
    return p.indexOf(perm) !== -1;
  }

  private isAdmin(p: string[]): boolean {
    return this.hasPermission(p, 'ADMIN');
  }
}
