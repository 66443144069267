import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Paginate } from '../interfaces/paginate';
import { SmemoveDiscountCode } from '../interfaces/smemove-discount-code';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class SmemoveDiscountService {

  constructor(private apiService: ApiClientService) { }

  prepareData(): Observable<any> {
    return this.apiService.get('smemove-discount-codes/prepare-data');
  }

  show(id: number): Observable<any> {
    return this.apiService.get(`smemove-discount-codes/${id}`);
  }
  create(data: any): Observable<any> {
    return this.apiService.post('smemove-discount-codes', data);
  }
  update(id: number, data: any): Observable<any> {
    return this.apiService.put(`smemove-discount-codes/${id}`, data);
  }

  discountCodes(query?: string): Observable<Paginate<SmemoveDiscountCode>> {
    return this.apiService.get(`smemove-discount-codes${query}`);
  }

  corporationDiscountCodes(corpId: number): Observable<Paginate<SmemoveDiscountCode>> {
    return this.apiService.get(`smemove-discount-codes/${corpId}/corp`);
  }

  inactive(id: number): Observable<SmemoveDiscountCode> {
    return this.apiService.put(`smemove-discount-codes/${id}/in-active`, {id});
  }

  active(id: number): Observable<SmemoveDiscountCode> {
    return this.apiService.put(`smemove-discount-codes/${id}/active`, {id});
  }

  delete(id: number): Observable<SmemoveDiscountCode> {
    return this.apiService.delete(`smemove-discount-codes`, id);
  }
}
